import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import foto1 from "../assets/IMG_5842.jpeg";
import foto2 from "../assets/IMG_5843.jpeg";
import foto3 from "../assets/IMG_5844.jpeg";
import foto4 from "../assets/IMG_5845.jpeg";
import foto5 from "../assets/IMG_5846.jpeg";
import foto6 from "../assets/IMG_5857.jpeg";
import foto7 from "../assets/IMG_5847.jpeg";
import foto8 from "../assets/IMG_5848.jpeg";
import foto9 from "../assets/IMG_5849.jpeg";
import foto10 from "../assets/IMG_5851.jpeg";
import foto11 from "../assets/IMG_5852.jpeg";
import foto12 from "../assets/IMG_5853.jpeg";
import foto13 from "../assets/IMG_5854.jpeg";
import foto14 from "../assets/IMG_5855.jpeg";
import foto15 from "../assets/IMG_5856.jpeg";

const slideImages = [
  {
    url: foto1,
    caption: 'Slide 1'
  },
  {
    url: foto2,
    caption: 'Slide 2'
  },
  {
    url: foto3,
    caption: 'Slide 3'
  },
  {
    url: foto4,
    caption: 'Slide 4'
  },
  {
    url: foto5,
    caption: 'Slide 5'
  },
  {
    url: foto6,
    caption: 'Slide 6'
  },
  {
    url: foto7,
    caption: 'Slide 7'
  },
  {
    url: foto8,
    caption: 'Slide 8'
  },
  {
    url: foto9,
    caption: 'Slide 9'
  },
  {
    url: foto10,
    caption: 'Slide 10'
  },
  {
    url: foto11,
    caption: 'Slide 11'
  },
  {
    url: foto12,
    caption: 'Slide 12'
  },
  {
    url: foto13,
    caption: 'Slide 13'
  },
  {
    url: foto14,
    caption: 'Slide 14'
  },
  {
    url: foto15,
    caption: 'Slide 15'
  },

];

const Gallery = () => {

  return (
        <div className="w-full lg:w-1/4 justify-center slide-container mt-6">
        <Slide>
         {slideImages.map((slideImage, index)=> (
            <div className="each-slide" key={index}>
              <img alt="Galéria" src={slideImage.url}>
              </img>
            </div>
          ))} 
        </Slide>
      </div>
  );
};

export default Gallery;
