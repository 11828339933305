import "../App.css";
import TypeWriter from "typewriter-effect";
import Logo from "../assets/noveLogo.png";
import {isActive,handleClick} from "../App.js";

const Homepage = ({isActive,handleClick}) => {
  
  
 

  return (
    <div id="imageSlider">
      <div id="home" className="bg-black w-full h-full bg-opacity-60 select-none">
        <div className="w-full h-screen lg:grid grid-col grid-rows-2 flex flex-col">
          <div>
            <div className="w-full flex">
              <section className="lg:container lg:mx-auto w-full flex items-center bg-black lg:px-2 bg-opacity-50 lg:bg-transparent justify-between font-main-font px-2 lg:p-0">
                <img className="lg:w-52 w-40 lg:pt-4" src={Logo} alt="Logo"></img>
                <div className="hidden lg:flex items-center space-x-5 text-white text-xl">
                  <a href="#about"><h1 className="menu-link">O nás</h1></a>
                  <a href="#galery"><h1 className="menu-link">Galéria</h1></a>
                  <a href="#contact"><h1 className="menu-link">Kontakt</h1></a>
                </div>
                
                  <div className="lg:hidden w-16 h-16 flex flex-col justify-center items-center space-y-2 cursor-pointer duration-500" onClick={isActive ? '' : handleClick}>
                  <div className={isActive ? 'w-9 h-1 bg-header rounded-2xl' : 'w-9 h-1 bg-white rounded-2xl'}></div>
                  <div className={isActive ? 'w-9 h-1 bg-header rounded-2xl' : 'w-9 h-1 bg-white rounded-2xl'}></div>
                  <div className={isActive ? 'w-9 h-1 bg-header rounded-2xl' : 'w-9 h-1 bg-white rounded-2xl'}></div>
                </div>
              </section>
            </div>
          </div>
          
          <div className="lg:h-fit flex-auto flex items-center justify-center text-white font-extrabold xl:text-6xl text-lg md:text-xl font-main-font uppercase">
            <TypeWriter
              options={{
                strings: [
                  "Stavba domov na kľúč",
                  "Obnova bytových domov",
                  "Zatepľovanie budov",
                ],
                autoStart: true,
                loop: true,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Homepage;
