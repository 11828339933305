import "./App.css";
import Homepage from "./components/Homepage.js";
import About from "./components/About";
import { EmailForm } from "./components/email_form";
import Gallery from "./components/Gallery";
import Footer from "./components/Footer";
import {useState} from 'react';


function App() {

  let [isActive, setIsActive] = useState(false);

  const handleClick = event => {
    setIsActive(current => !current);
  };
  return (

    <div className="relative font-main-font">
      <div className={isActive ? 'fixed lg:hidden z-10 right-0 bottom-0 w-1/2 h-screen bg-main-green opacity-90 active:bg-black transition ease-in duration-500' : 'hidden'}>
                <div className="pt-24">
                <h1 className="right-0 z-50 block text-right fa fa-times justify-center text-2xl pr-8 pt-4 text-white cursor-pointer" onClick={handleClick}></h1>
                  <div className="flex flex-col space-y-4 text-center pt-32 text-white">
                  
                  <a href="#about"><h1 className="text-2xl">O nás</h1></a>
                  <a href="#galery"><h1 className="text-2xl">Galéria</h1></a>
                  <a href="#contact"><h1 className="text-2xl">Kontakt</h1></a>
                  </div>
                </div>
                </div>
      <Homepage isActive={isActive} handleClick={handleClick}></Homepage>
      <div id="about">
        <About></About>
      </div>
      <div class="flex flex-wrap mb-16">
        <div id="galery" class="w-full justify-center flex">
          <Gallery></Gallery>
        </div>
        <div id="contact" class="w-full justify-center flex">
          <EmailForm></EmailForm>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default App;
