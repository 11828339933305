import { Component, useState } from "react";

function myInput(type, placeholder, req, style, variable, setter, obj, val) {
  if (req) {
    return (
      <div className="flex">
        <input
          value={val}
          onChange={(e) => setter({ ...obj, [variable]: e.target.value })}
          required={req}
          placeholder={placeholder}
          className={
            style
              ? style
              : "w-full bg-gray-200 px-4 p-3 rounded-md focus:outline-none"
          }
          type={type}
        ></input>
        <label className="text-red-700 text-xs select-none">*</label>
      </div>
    );
  } else {
    return (
      <div className="flex">
        <input
          value={val}
          onChange={(e) => setter({ ...obj, [variable]: e.target.value })}
          placeholder={placeholder}
          className={
            style
              ? style
              : "w-full bg-gray-200 rounded-md px-4 p-3 focus:outline-none"
          }
          type={type}
        ></input>
        <label className="text-transparent text-xs select-none">*</label>
      </div>
    );
  }
}

async function sendEmail(data) {
  console.log(data);
  return fetch("http://localhost:8080/index.php", {
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function EmailForm() {
  const [data, setData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [disabled, setDisabled] = useState(false);

  const loadingFile = (
    <button
      type="submit"
      className="inline-flex text-white rounded-md px-4 p-2 w-full bg-gray-800"
      disabled
    >
      <svg
        className="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
      Odosiela sa..
    </button>
  );

  const sendButton = (
    <button
      type="submit"
      className="text-white rounded-md p-2 w-full bg-gray-800 font-bold text-xl"
    >
      Odoslať
    </button>
  );
  const successfulButton = (
    <button
      type="submit"
      disabled={true}
      className="gap-x-8 text-white rounded-md p-2 w-full bg-gray-800 flex"
    >
      <i class="fa fa-check animate-pulse" aria-hidden="true"></i>
      <p>Odoslané</p>
    </button>
  );

  const [Button, SetButton] = useState(sendButton);

  return (
    <div>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          SetButton(loadingFile);
          setDisabled(true);
          console.log(disabled);
          sendEmail(data).then(() => {
            setTimeout(() => {
              SetButton(sendButton);
            }, 5000);
            SetButton(successfulButton);
            setDisabled(false);
            setData({
              name: "",
              email: "",
              subject: "",
            });
          });
        }}
      >
        <fieldset
          className="bg-gray-100 rounded-2xl md:py-8 p-8 md:px-16 mt-16 lg:flex lg:space-x-4"
          disabled={disabled}
        >
          <div className="lg:w-1/2 space-y-4">
            <h1 className="text-font-green text-xl text-center lg:text-left font-bold">
              Kontaktujte nás
            </h1>
            <p className="w-full text-md lg:text-left text-center">
              Sme tu pre vás !<br className="lg:hidden" /> Ako vám môžeme pomôcť
              ?{" "}
            </p>
            <div className="space-y-4 w-full">
              {myInput(
                "text",
                "Meno",
                true,
                null,
                "name",
                setData,
                data,
                data.name
              )}
              {myInput(
                "email",
                "E-mail",
                true,
                null,
                "email",
                setData,
                data,
                data.email
              )}
              {myInput(
                "text",
                "Predmet",
                false,
                null,
                "subject",
                setData,
                data,
                data.subject
              )}

              <div className="flex">
                <textarea
                  value={data.message}
                  onChange={(e) =>
                    setData({ ...data, message: e.target.value })
                  }
                  className="w-full rounded-md bg-gray-200 p-3 px-4 resize-none h-32 focus:outline-none"
                  placeholder="Správa"
                ></textarea>
                <label className="text-transparent text-xs select-none">
                  *
                </label>
              </div>

              <div className="flex w-full">
                {Button}
                <label className="text-transparent text-xs select-none">
                  *
                </label>
              </div>
            </div>
          </div>

          <div className="lg:w-1/2 pt-12">
            <div className="space-y-4">
              <div className="flex items-center">
                <i
                  class="text-center pr-3 fa fa-envelope text-2xl text-green-900 w-1/6"
                  aria-hidden="true"
                ></i>
                <p className="w-5/6 text-gray-800">smfontana.chominec@gmail.com</p>
              </div>

              <div className="flex items-center">
                <i
                  class="text-center pr-3 fa fa-phone text-2xl text-green-900 w-1/6"
                  aria-hidden="true"
                ></i>
                <p className="w-5/6 text-gray-800">+421 918 480 762</p>
              </div>

              <div className="flex items-center">
                <i
                  class="text-center pr-3 fa fa-map-marker text-2xl text-green-900 w-1/6"
                  aria-hidden="true"
                ></i>
                <p className="w-5/6 text-gray-800">Prostějovská 55, 080 01 Prešov</p>
              </div>

              <div className="lg:flex items-center justify-center hidden">
                <iframe
                  className="mx-8 w-2/3 lg:h-64 h-32"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2616.480700441889!2d21.223882251656413!3d49.02046379713301!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473ef2b820ec2f37%3A0x36fdb7d0c9b0d018!2zUHJvc3TEm2pvdnNrw6EgNTUsIDA4MCAwMSBQcmXFoW92!5e0!3m2!1ssk!2ssk!4v1674511508696!5m2!1ssk!2ssk"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
          <div className="mt-4 block lg:hidden">
            <iframe
src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2616.480700441889!2d21.223882251656413!3d49.02046379713301!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473ef2b820ec2f37%3A0x36fdb7d0c9b0d018!2zUHJvc3TEm2pvdnNrw6EgNTUsIDA4MCAwMSBQcmXFoW92!5e0!3m2!1ssk!2ssk!4v1674511508696!5m2!1ssk!2ssk"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </fieldset>
      </form>
    </div>
  );
}
