import React from "react";
import Logo from "../assets/noveLogo.png";

const Footer = () => {
  return (
    <div className="w-full bg-gray-800 text-white p-4 md:p-8 items-center gap-x-2 relative flex text-center space-y-4 md:space-y-0 justify-center" >
      <h1 className="lg:text-2xl ">All rights reserved SMFONTANA s.r.o.</h1>
      
    </div>
  );
};

export default Footer;
