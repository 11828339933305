import React from "react";
import about1 from "../assets/about1.jpg";
import about2 from "../assets/about2.jpg";

export default function About() {
  return (
    <div>
      <div className="w-full flex items-center justify-center pt-12">
        <h1 className="pb-4 uppercase xl:w-1/3 w-2/3 text-center text-4xl font-bold text-font-green leading-none border-b border-font-green">
          O nás
        </h1>
      </div>

      <section className="grid lg:grid-cols-8 lg:grid-rows-2 grid-cols-1 xl:gap-x-20 lg:gap-x-10 lg:gap-y-10">
        <div className="2xl:col-span-2 2xl:col-start-3 xl:col-start-2 xl:col-span-3 lg:col-start-1 lg:col-span-4 lg:text-right text-left pt-10">
          <h1 className="text-font-green text-center lg:text-right text-xl font-bold">
            Niečo málo o nás
          </h1>
          <p className="font-medium xl:p-0 xl:pt-3  lg:pt-2 lg:px-0 lg:pl-10 p-3 sm:px-20">
            <b>Sme obchodno - stavebná firma</b> so širokou paletou ponúkaných
            služieb. Našou špecializáciou v rámci našich tehcnických a
            kapacitných možností je stavebná činnosť v komplexnom rozsahu. Okrem
            hlavného zamerania poskytujeme rozsiahle portfólio služieb, ktoré
            dopĺňajú hlavnú činnosť spoločnosti tak, aby boli pokryté všetky
            potreby na stavebnom trhu. Naším cieľom a prioritou je vyhovieť
            našim zákaznikom a poskytovanie kvalitných služieb. Firma má aj
            možnosť poskytnút energetický certifikát.
          </p>
        </div>

        <div className="2xl:col-span-2 2xl:col-start-5  2xl:flex 2xl:items-center xl:col-span-3 xl:col-start-5 lg:col-start-5 lg:col-span-4 lg:row-start-1 row-start-2 lg:pt-5 xl:pt-none">
          <img className="w-full" src={about1} alt="AboutImage"></img>
        </div>

        <div className="2xl:col-span-2 2xl:col-start-3 2xl:flex 2xl:items-center xl:col-span-3 xl:col-start-2 lg:col-span-4 lg:col-start-1 lg:row-start-2 row-start-4">
          <img className="w-full" src={about2} alt="AboutImage"></img>
        </div>

        <div className="2xl:col-span-2 2xl:col-start-5  xl:col-span-3 xl:col-start-5 lg:col-span-4 lg:col-start-5 lg:row-start-2 text-left pt-10">
          <h1 className="text-font-green text-xl text-center lg:text-left font-bold">
            Prečo si vybrať nás?
          </h1>
          <p className="font-medium xl:p-0 xl:pt-3 lg:pt-2 lg:px-0 lg:pr-10 p-3 sm:px-20">
            Poskytujeme množstvo služieb, ktoré sú potrebné už od začiatku
            stavby až po jej dokončenie -{" "}
            <b>
              kamenárske, meliorizačné, tesárske, stolárske a iné remeselné
              služby
            </b>{" "}
            . Našou vedľajšou činnosťou je aj poradenská činnosť a nákladná
            doprava do 3,5t. Okrem všetkých týchto služieb môžeme vystaviť
            <b> energetický certifikát</b>. Ak to nestačí na presvedčenie tak si
            stačí pozrieť našu históriu a fotky predošlých projektov.
          </p>
        </div>
      </section>

      <div className="w-full flex items-center justify-center pt-12">
        <h1 className="pb-4 uppercase xl:w-1/3 w-2/3 text-center text-4xl font-bold text-font-green leading-none border-b border-font-green"></h1>
      </div>

      <section className="mt-20 w-full justify-center lg:flex hidden">
        <div className="w-1/5 grid grid-rows-4 my-2 text-right font-medium">
          <div className="flex flex-row-reverse row-start-2 pt-1">
            <h1 className="text-xl font-light mt-1">2014</h1>

            <div className="mr-2 space-y-5">
              <div className="flex flex-row-reverse justify-start items-center">
                <div class="h-0 w-0 border-y-[18px] border-y-transparent border-l-[32px] border-l-font-green"></div>
                <h1 className="w-44 text-center py-1 text-xl font-bold uppercase bg-font-green text-white ">
                  1. Projekty
                </h1>
              </div>

              <p className="text-xl max-w-xs">
                Postupom času prichádzali nové projekty, ktoré nám pomohli pri
                rozbehu
              </p>
            </div>
          </div>

          <div className="flex flex-row-reverse row-start-4 mt-4 pt-[3px]">
            <h1 className="text-xl font-light mt-1">2022</h1>

            <div className="mr-2 space-y-5">
              <div className="flex flex-row-reverse justify-start items-center">
                <div class="h-0 w-0 border-y-[18px] border-y-transparent border-l-[32px] border-l-font-green"></div>
                <h1 className="w-44 text-center py-1 text-xl font-bold uppercase bg-font-green text-white ">
                  Súčasnosť
                </h1>
              </div>

              <p className="text-xl max-w-xs">
                Nasledovali bytové domy a rodinné domy v Terni, Kapušanoch pri
                Prešove, Vyšnej Šebastovej a Ľuboticiach. Do budúcnosti firma
                plánuje stavbu zrubových a slamených eko domov
              </p>
            </div>
          </div>
        </div>

        <div className="col-start-3 mx-5 row-span-full flex flex-col items-center space-y-3">
          <div className="w-10 h-10 rounded-full bg-font-green"></div>
          <div className="w-2 h-8 bg-gray-300 "></div>
          <div className="w-2 h-8 bg-gray-300 "></div>
          <div className="w-2 h-8 bg-gray-300 "></div>
          <div className="w-2 h-8 bg-gray-300 "></div>
          <div className="w-8 h-8 rounded-full bg-font-green"></div>
          <div className="w-2 h-8 bg-gray-300 "></div>
          <div className="w-2 h-8 bg-gray-300 "></div>
          <div className="w-2 h-8 bg-gray-300 "></div>
          <div className="w-2 h-8 bg-gray-300 "></div>
          <div className="w-8 h-8 rounded-full bg-font-green"></div>
          <div className="w-2 h-8 bg-gray-300 "></div>
          <div className="w-2 h-8 bg-gray-300 "></div>
          <div className="w-2 h-8 bg-gray-300 "></div>
          <div className="w-2 h-8 bg-gray-300 "></div>
          <div className="w-10 h-10 rounded-full bg-font-green"></div>
        </div>

        <div className="w-1/5 grid grid-rows-4 text-left font-medium">
          <div className="flex">
            <h1 className="text-xl font-light mt-1">2008</h1>

            <div className="ml-2 space-y-5">
              <div className="flex items-center">
                <div class="h-0 w-0 border-y-[18px] border-y-transparent border-r-[32px] border-r-font-green"></div>
                <h1 className="w-44 text-center py-1 text-xl font-bold uppercase bg-font-green text-white ">
                  Vznik
                </h1>
              </div>

              <p className="text-xl max-w-xs">
                Firma SMFontana vznikla <b>v roku 2008</b>. Prvé roky boli
                náročnejsie, ale aj tak sme sa nevzdávali
              </p>
            </div>
          </div>

          <div className="row-start-3 flex pt-2">
            <h1 className="text-xl font-light mt-1">2018</h1>

            <div className="ml-2 space-y-5">
              <div className="flex items-center">
                <div class="h-0 w-0 border-y-[18px] border-y-transparent border-r-[32px] border-r-font-green"></div>
                <h1 className="w-44 text-center py-1 text-xl font-bold uppercase bg-font-green text-white ">
                  Rozkvet
                </h1>
              </div>

              <p className="text-xl max-w-xs">
                <b>Od roku 2014 do 2018</b> firma zažila najväčší rozkvet. V
                tomto období sme zažili veľké zatepľovania na bytových domov na
                Šrobárovej a Bernolákovej ulici.
              </p>
            </div>
          </div>
        </div>
      </section>

      <div className="w-full lg:flex items-center justify-center pt-20 hidden">
        <h1 className="pb-4 uppercase xl:w-1/3 w-2/3 text-center text-4xl font-bold text-font-green leading-none border-b border-font-green"></h1>
      </div>
    </div>
  );
}
